import React from 'react';
import WithTranslator from '../../providers/WithTranslator';

import './Footer.scss';
import logo from '../../resources/logo.svg';

class Footer extends React.Component {
    componentDidMount() {
        this.header = document.getElementById('header');
        this.transferPart = document.getElementById('transferPart');
        this.commissionPart = document.getElementById('commissionPart');
        this.questionsPart = document.getElementById('questionsPart');
        this.contactsPart = document.getElementById('contactsPart');
    }

    goTo = element => window.scrollTo({top: element.offsetTop - 67, behavior: 'smooth'});

    render() {
        const translator = this.props.translator;
        return (
            <>
                <footer className='appbar'>
                    <img src={logo} alt='BEST' className='logo'/>

                    <div className='menu'>
                        <a onClick={() => this.goTo(this.header)}>{translator('header.link.main')}</a>
                        <a onClick={() => this.goTo(this.transferPart)}>{translator('header.link.transfer')}</a>
                        <a onClick={() => this.goTo(this.commissionPart)}>{translator('header.link.commission')}</a>
                        <a onClick={() => this.goTo(this.questionsPart)}>{translator('header.link.questions')}</a>
                        <a onClick={() => this.goTo(this.contactsPart)}>{translator('header.link.contacts')}</a>
                    </div>
                </footer>
                <div className='_short'>
                    <img src={logo} alt='BEST'/>
                </div>
            </>
        )
    }
}

export default WithTranslator(Footer);
