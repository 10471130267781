import React from 'react';
import {Paper} from '@material-ui/core';
import TextInput from '../textInpit/TextInput';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';

class TransferCardForm extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this.props.storage);
    }

    render() {
        const {translator, storage} = this.props;
        return (
            <Paper className='margin-bottom'>
                <span className='h3 margin-bottom'>{this.props.title}</span>

                <TextInput label={translator('transferPart.form.card.placeholder')}
                           className='margin-bottom'
                           error={storage.state.errors.cardError}
                           value={storage.state.fields.card}
                           onChange={this.FHelper.onCardChange}
                           onBlur={this.FHelper.onCardBlur}
                           type='cc-number'
                />

                <TextInput label={translator('transferPart.form.owner.placeholder')}
                           className='margin-bottom'
                           error={storage.state.errors.ownerError}
                           value={storage.state.fields.owner}
                           onChange={this.FHelper.onOwnerChange}
                           type='cc-name'
                />

                <div className='inline-fields'>
                    {this.props.withDate &&
                        <>
                            <TextInput label={translator('transferPart.form.month.placeholder')}
                                       error={storage.state.errors.monthError}
                                       value={storage.state.fields.month}
                                       onBlur={this.FHelper.onMonthBlur}
                                       onChange={this.FHelper.onMonthChange}
                                       type='cc-exp-month'
                            />
                            <TextInput label={translator('transferPart.form.year.placeholder')}
                                       error={storage.state.errors.yearError}
                                       value={storage.state.fields.year}
                                       onChange={this.FHelper.onYearChange}
                                       type='cc-exp-year'
                                       autoComplete='off'
                            />
                        </>
                    }
                    {this.props.withCvv &&
                    <TextInput label={translator('transferPart.form.cvv.placeholder')}
                               error={storage.state.errors.cvvError} type='password' autoComplete='off'
                               value={storage.state.fields.cvv}
                               onChange={this.FHelper.onCvvChange}/>

                    }
                </div>
            </Paper>
        )
    }
}

export default WithTranslator(TransferCardForm);
