import React from 'react';
import {Button} from '@material-ui/core';
import TransferSendForm from './TransferSend';
import TransferGetForm from './TransferGet';
import WithTranslator from '../../providers/WithTranslator';
import WithTransferMode from '../../providers/WithTransferMode';

import './TransferPart.scss';
import cardLogo1 from '../../resources/icons/cardLogo1.svg';``
import cardLogo2 from '../../resources/icons/cardLogo2.svg';
import cardLogo3 from '../../resources/icons/cardLogo3.svg';
import cardLogo4 from '../../resources/icons/cardLogo4.svg';
import cardLogo5 from '../../resources/icons/cardLogo5.svg';
import cardLogo6 from '../../resources/icons/cardLogo6.svg';
import cardLogo7 from '../../resources/icons/cardLogo7.svg';

class TransferPart extends React.Component {
    render() {
        const translator = this.props.translator;
        return (
            <div className='transfer-part' id='transferPart'>
                <div className='transfer-part__inner'>
                    <span className='h2'>{translator('transferPart.title')}</span>

                    <div className='menu'>
                        <Button onClick={() => this.props.setTransferMode(0)}
                            className={`_large ${this.props.transferMode === 0 ? 'primary' : 'secondary'}`}>
                            {translator('transferPart.menu.btn.makeTransfer')}
                        </Button>
                        <Button onClick={() => this.props.setTransferMode(1)}
                                className={`_large ${this.props.transferMode === 1 ? 'primary' : 'secondary'}`}>
                            {translator('transferPart.menu.btn.receiveTransfer')}
                        </Button>
                    </div>

                    {this.props.transferMode === 0
                        ? <TransferSendForm/>
                        : <TransferGetForm/>
                    }

                    <div className='icons'>
                        <img src={cardLogo1} alt='logo'/>
                        <img src={cardLogo2} alt='logo'/>
                        <img src={cardLogo3} alt='logo'/>
                        <img src={cardLogo4} alt='logo'/>
                        <img src={cardLogo5} alt='logo'/>
                        <img src={cardLogo6} alt='logo'/>
                        <img src={cardLogo7} alt='logo'/>
                    </div>

                    <hr/>

                    <span className='warning'>{translator('transferPart.warning')}</span>
                </div>
            </div>
        )
    }
}

export default WithTransferMode(WithTranslator(TransferPart));
