import React from 'react';
import {Button, Checkbox, Paper} from '@material-ui/core';
import TransferCardForm from './TransferCardForm';
import TextInput from '../textInpit/TextInput';
import Tooltip from '../tooltip/Tooltip';
import WithTranslator from '../../providers/WithTranslator';
import FieldsHelper from './FieldsHelper';
import FValidator from './FieldsValidator';

import {ReactComponent as IconQuestion} from '../../resources/icons/icon-question.svg';

const defaultFields = {
    amount: '',
    card: '',
    owner: '',
    month: '',
    year: '',
    cvv: '',
    deferPayout: false,
    agree: false,
};

class TransferSendFirstStep extends React.Component {
    constructor(props) {
        super(props);

        this.FHelper = new FieldsHelper(this);
        const fields = this.props.fields ? {...this.props.fields} : {...defaultFields};
        this.state = {
            fields,
            errors: {}
        };
    }

    onSubmit = () => {

        const errors = {
            amountError: !FValidator.isAmountValid(this.state.fields.amount),
            cardError: !FValidator.isCardValid(this.state.fields.card),
            ownerError: !FValidator.isOwnerValid(this.state.fields.owner),
            monthError: !FValidator.isMonthValid(this.state.fields.month),
            yearError: !FValidator.isYearValid(this.state.fields.year),
            cvvError: !FValidator.isCvvValid(this.state.fields.cvv),
            agreeError: !this.state.fields.agree,
        };
        if (Object.values(errors).some(i => !!i)) {
            this.setState({errors: {...errors}});
        } else {
            this.props.onSubmit({...this.state.fields});
        }
    }

    onCancel = () => {
        this.setState({fields: {...defaultFields}, errors: {}});
        this.props.onCancel();
    }

    render() {
        const translator = this.props.translator;
        const commission = this.state.fields.amount < 200
            ? this.state.fields.amount * 0.015
            : this.state.fields.amount * 0.02;
        return (
            <>
                <Paper className='margin-bottom amount'>
                    <span className='h3 margin-bottom'>{translator('transferPart.form.amount.title')}</span>

                    <TextInput label={translator('transferPart.form.amount.placeholder')}
                               value={this.state.fields.amount} onChange={this.FHelper.onAmountChange}
                               error={this.state.errors.amountError}
                               onBlur={this.FHelper.onAmountBlur}/>
                    <span className='currency'>₽</span>
                </Paper>

                <TransferCardForm storage={this} title={translator('transferPart.form.card1.title')}
                                  withCvv={true} withDate={true} />

                <Paper className='commission margin-bottom'>
                    {translator('transferPart.form.commission1')}
                    <span>{commission.toFixed(2)}</span>
                    {translator('transferPart.form.commission2')}
                </Paper>

                <div className='agree-part'>
                    <Checkbox checked={this.state.fields.deferPayout}
                              onChange={() => this.setState({
                                  fields: {
                                      ...this.state.fields,
                                      deferPayout: !this.state.fields.deferPayout
                                  }
                              })}/>
                    <span className='margin-right'>
                        {translator('transferPart.form.code')}
                    </span>
                    <Tooltip title={translator('transferPart.form.code.tooltip')}
                             arrow={document.documentElement.clientWidth > 800}
                             placement={document.documentElement.clientWidth > 800 ? 'right-start' : 'bottom'}
                             leaveTouchDelay={5000}
                             enterTouchDelay={10}>
                        <IconQuestion className='_clickable'/>
                    </Tooltip>
                </div>

                <div className='agree-part margin-bottom'>
                    <Checkbox checked={this.state.fields.agree}
                              onChange={() =>
                                  this.setState({fields: {...this.state.fields, agree: !this.state.fields.agree}})
                              }/>
                    <span>
                            {translator('transferPart.form.agree1')}&nbsp;
                        {/*<a href="" target='_blank'>*/}
                        {translator('transferPart.form.agree2')}
                        {/*</a>*/}
                    </span>
                </div>

                <div className='controls'>
                    <Button className='primary _large' onClick={this.onSubmit}
                            disabled={
                                !Object.entries(this.state.fields).every(([k, v]) => !!v || k === 'deferPayout')
                                || Object.values(this.state.errors).some(e => !!e)
                            }>
                        {translator('transferPart.form.btn1.submit')}
                    </Button>
                    {Object.values(this.state.fields).some(f => !!f) &&
                    <Button className='secondary _large' onClick={this.onCancel}>
                        {translator('transferPart.form.btn1.cancel')}
                    </Button>
                    }
                </div>
            </>
        )
    }
}

export default WithTranslator(TransferSendFirstStep);
